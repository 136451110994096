import Vue from "vue";
import VueI18n from "vue-i18n";
Vue.use(VueI18n);
Vue.use(VueI18n);
const messages = {
  es: {
    home: "Home",
    websiteIntro: "<p>Esta promoción ha confiado en la experiencia de Porcelanosa Grupo y sus más de 50 años de trayectoria en el sector residencial para que el 100% de los acabados de nuestras viviendas sean de calidad y puedas tener la máxima garantía.</p><p>Junto a Porcelanosa Partners, el área especializada en proyectos residenciales, hemos desarrollado las soluciones más apropiadas acorde a las necesidades del proyecto, contando siempre con el asesoramiento y supervisión directa de sus mejores profesionales y toda nuestra dedicación.</p>",
    gallery3D: "Galería 3D",
    gallerySubtitle: "Deja de imaginar y empieza a visualizar<br>las estancias de tu futura vivienda.",
    websiteImageBox1: "Salón",
    websiteImageBox2: "Cocina",
    websiteImageBox3: "Baño 1",
    websiteImageBox4: "Baño 2",
    websiteImageBox5: "Dormitorio",
    websiteImageBox6: "Terraza",
    availableFinishes: "acabados<br>disponibles",
    virtualTour: "Tour Virtual",
    virtualTourSubtitle: "Recorrido virtual por las diferentes<br>estancias de la vivienda.",
    customizationApp: "App personalización",
    customAppSubtitle: "App Porcelanosa Partners Spaces<br>para personalizar los ambientes del<br>residencial y seleccionar de acabados.",
    film3D: "3D Film",
    film3DSubtitle: "Experiencia audiovisual del interior<br>de la vivienda con la que ver los<br>ambientes con vida.",
    catalog: "Catálogo",
    catalogSubtitle: "Catálogo para ver la presentación<br>de la promoción residencial y elegir<br>acabados.",
    download: "Descargar",
    contact: "Contacto",
    addressPromotion: "Dirección Promoción",
    addressShowroom: "Dirección Showroom",
    allRightsReserved: "Todos los derechos reservados.",
    app_id: "2",
    websiteTitle: "Ponte Burgo",
    websiteSubtitle: "¿te quedas a vivir aquí?",
    websiteWebPromoter: "www.grupopirsa.com",
    websitePhonePromoter: "986 871 877",
    websiteEmailPromoter: "info@grupopirsa.com",
    websiteAddressShowroom: "Pontevedra",
    websiteAddressPromoter: "Pontevedra",
  },
};
export const i18n = new VueI18n({
   locale: window.navigator.language.split("-")[0],
   fallbackLocale: "es",
   messages,
});

