<template>
  <div id="home" class="home-slide">
    <v-layout justify-center align-center column>
      <div style="position: absolute; bottom: 50%">
        <v-img
          v-if="this.$exist_logotype"
          :src="require('/public/resources/logotype.png')"
          class="text-center"
          max-width="400"
        ></v-img>
        <h1
          v-if="this.$title && !this.$exist_logotype"
          class="text-md-h1 text-h4 font-weight-black white--text text-center"
        >
          {{ $t("websiteTitle") }}
        </h1>
        <h2
          v-if="this.$subtitle && !this.$exist_logotype"
          class="text-md-h2 text-h5 font-weight-black white--text text-center"
        >
          {{ $t("websiteSubtitle") }}
        </h2>
      </div>

      <div style="position: absolute; bottom: 10px">
        <!-- prettier-ignore -->
        <v-img
          :src="require('/public/img_lang/' + $i18n.locale + '/logo_porcelanosa_acabados.png')"
          max-width="260"
        ></v-img>
      </div>
    </v-layout>
  </div>
</template>

<script>
export default {
  name: "HomeSlide",
};
</script>

<style scoped>
.home-slide {
  --opacidad-negro: 0.4;
  background: linear-gradient(
      rgba(0, 0, 0, var(--opacidad-negro)),
      rgba(0, 0, 0, var(--opacidad-negro))
    ),
    url("/public/resources/imgSlideshow.jpeg");
  background-position: center center;
  min-height: 100vh;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: relative;
}
</style>
